<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공사기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="2M"
            label="LBL0001800"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="공사 진행상태-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공사기간 -->
          <c-select
            type="search"
            :comboItems="finishFlagItems"
            itemText="codeName"
            itemValue="code"
            name="constructionFinishFlag"
            label="LBL0001801"
            v-model="searchParam.constructionFinishFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 공사 목록 -->
    <c-table
      ref="table"
      title="LBL0001802"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'construction',
  data() {
    return {
      searchParam: {
        period: [],
        constructionFinishFlag: 'N'
      },
      grid: {
        columns: [
          {
            name: 'constructionName',
            field: 'constructionName',
            // 공사명
            label: 'LBL0001803',
            align: 'left',
            style: 'width:220px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'constructionPeriod',
            field: 'constructionPeriod',
            // 공사명
            label: 'LBL0001804',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
          {
            name: 'onSiteManager',
            field: 'onSiteManager',
            // 현장 총괄
            label: 'LBL0001805',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'safetyManager',
            field: 'safetyManager',
            // 안전책임자
            label: 'LBL0001806',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            // 주 공사업체
            label: 'LBL0001807',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'constructionFinishFlagName',
            field: 'constructionFinishFlagName',
            // 공사 진행상태
            label: 'LBL0001801',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      finishFlagItems: [
        { code: 'N', codeName: '진행중' },
        { code: 'Y', codeName: '종료' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.scm.construction.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = 'LBL0001808'; // 공사 상세
      this.popupOptions.param = {
        sopConstructionId: row ? row.sopConstructionId : '',
      };
      this.popupOptions.target = () => import(`${'./constructionDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
